<template lang="html">
    <div style="height:100%">
        <div class="handleBox flex flex-jc-sb flex-ai-c" >
            <p class="title">学生管理 ></p>
            <div class="">
                <el-button size="small" type="primary" @click="getZip()">批量打包下载二维码</el-button>
            </div>
        </div>
        <!-- 表格 -->
        <div class="table" style="height:calc( 100% - 95px )">
            <el-table
                :data="list"
                highlight-current-row stripe border
                :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}"
                @sort-change='getList()'
                height="calc( 100% - 41px )"
                row-key="id"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            >
                <el-table-column
                    prop="name"
                    label="名称"
                    sortable
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="key"
                    label="二维码">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.key" size="small" @click="getImg(scope.row)">二维码</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    <el-dialog
            :modal-append-to-body="true" :append-to-body="true" :close-on-click-modal="false"
            title="二维码"
            :visible.sync="DialogVisible"
            width="250px"
            center>
        <el-image
            style="width: 200px; height: 200px"
            fit = 'contain'
            :src="url">
        </el-image>
    </el-dialog>
    </div>
</template>

<script>
export default {
    data: function() {
        return {

            list: [],
            url: '',
            DialogVisible: false

        }
    },
    mounted: function() {
        this.getList()
    },
    watch: {

    },
    methods: {
        getList: function() {
            this.http.post(
                '/admin.setting/getMuLu').then(re => {
                this.list = re;
                // console.log(this.list)
            })
        },
        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
        getImg: function(row) {
            console.log(row.key)
            this.http.post(
                '/admin.qrcode/one', {
                    key: row.key
                }).then(re => {
                this.url = re.path;
                this.DialogVisible = true
                // console.log(this.list)
            })
        },
        getZip: function() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
                loading.close();
            }, 5000);
            location.href = window.domain +
                '/admin.qrcode/batch';

        }

    }
}
</script>
